import React from 'react';
import './Landing.css';
import Land from '../images/dessert (9).jpg';
import { Button} from 'react-bootstrap';

function Landing() {
return (
    <div class="gallary-landing">
        <img src={Land} className='image-back'alt=''/>
            <div class="landing-para">
                <h1>Foodixa</h1>
                <p>IT'S NICE TO MEET YOU</p>
                <p>Wherever we are, there is a delicious distinctive taste
                We always work hard to provide the best
                meals and the most delicious foods that suit all family members</p>
                <Button variant="outline-warning">learn more</Button>
            </div>
        </div>

)
}

export default Landing;
