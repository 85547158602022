import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom'
import { BiCart } from 'react-icons/bi';
import {useCart} from 'react-use-cart'
import { Button } from 'react-bootstrap';

function Footer() {
    const { totalItems} = useCart();
    const scrollTop =()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth',
        })
    }
    
    return (
    <div>
        <div class="footer">
            <Button variant="outline-warning" onClick={scrollTop} className='to-top'>
            <i class="fa-solid fa-chevron-up"></i>
            </Button>
            <div className='main-logo'>
            <Button variant="outline-warning"><Link to="/HomeRestaurant">Foodixa</Link></Button>
            <div class="subscrib">
                <button>subscribe</button>
                <input class="yemail" type="email" placeholder="Type Your Email"></input>
            </div>
            </div>
            <div class="linkes">
                <div className='home-link'>
                    <h1>Quick links</h1>
                    <p><Link to="/Menus"  >menus</Link></p>
                    <p><Link to="/Gallary"  >Gallary</Link></p>
                    <p><Link to="/About"  >about us</Link></p>
                    <p><Link to="/Chefs"  >chefs</Link></p>
                    <p><Link to="/MyCart" className='cart-link'  >my cart <BiCart size='2rem' /><span className="count">{totalItems}</span></Link></p>
                </div>
                <div className='page-link'>
                    <h1>pages links</h1>
                    <p><Link to="/Desserts"  >Desserts</Link></p>
                    <p><Link to="/Fruits"  >Fruits and juices</Link></p>
                    <p><Link to="/IceCream"  >ice cream</Link></p>
                    <p><Link to="/Coffee"  >coffee</Link></p>
                    <p><Link to="/Blog"  >blog</Link></p>
                </div>
                <div className='main-link'>
                    <h1>Home links</h1>
                    <p><Link to="/HomeRestaurant"  >Restaurant Home </Link></p>
                    <p><Link to="/HomeFastFood"  > Fast food home</Link></p>
                    <p><Link to="/HomeBigMeals"  > big meals home</Link></p>
                    <p><Link to="/HomeGirlled"  > Grilled home</Link></p>
                    <p><Link to="/Menus"  >menus</Link></p>
                </div>
            </div>
            <div class="social-media">
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-linkedin"></i>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-youtube"></i>
            </div>
            <div class="social-media">
                <h1>call for order:</h1>
                <h2>+158723654</h2>
                <h1>location:</h1>
                <h2>33 long ST park</h2>
                <h3>hello@example.com</h3>
            </div>
            <div className='footer-buttom'>
                <h1>&copy; Foodixa 2024</h1>
            </div>
        </div>
        
    </div>
    )
}

export default Footer
