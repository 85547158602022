import React from 'react'
import './Description.css'
import desserts1 from '../images/dessert (4).jpg'
import desserts8 from '../images/dessert (8).jpg'

function Description() {
  return (
    <div className='description-main'>
        <div className='description-body'>
              <div className='description-letter'>
                <h1>why desserts ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            <div className='description-image'>
              <img src={desserts1} alt=''/>
            </div>
      </div>
        <div className='description-body'>
              <div className='description-image'>
              <img src={desserts8} alt=''/>
            </div>
              <div className='description-letter'>
                <h1>why desserts from foodixa ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            
      </div>
    </div>
  )
}

export default Description
