import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import './Offers.css'
import { Link } from 'react-router-dom';
import img1 from './images/big meal.png'
import img2 from './images/girrled.jpg'
import img3 from './images/burger.jpg'
import img4 from './images/juice.png'
import img5 from './images/pizza.png'
import video1 from './images/burger-video.mp4'
import video2 from './images/girrled-vedio.mp4'
import video3 from './images/pizza video.mp4'
import video4 from './images/big meal video.mp4'
import video5 from './images/juice.mp4'




function Offers() {
    const offers =["big meals","grilled", "burger", "juice","pizza"];
    const [offer, setOffer]=useState('');

return (
    
        <div className="offers">
            <div className="wrapper">
                <h1>ask for foodixa offers</h1>
                <div className="wrapper-left">
                    <ul>
                        {offers.map(offer =>(
                            <li
                            key={offer}
                            onClick={()=>setOffer(offer)}
                            >
                            <p>{offer.toLocaleLowerCase()}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div class="wrapper-right">
                    {offer === "big meals" &&(
                        <div class="sec " >
                            <div class="sec-info">
                                <video src={video4} autoPlay loop muted/>
                                <h1>big meals</h1>
                                <img src={img1} alt=''/>
                                <p>Whether it is dinner, breakfast, or lunch, family meals offer an opportunity for all family members to pass time
                                enjoying each others company and good food. In many occasions, mealtimes might be the only occasion many families have
                                a chance to congregate as a unit. Eating meals as a family provides a huge opportunity to form and strengthen domestic
                                bonds as members engage each other in conversations. Also, research shows that family meals benefit members of the
                                household in terms of nutrition, academic performance, spending and manners. This expressive article highlights the nine
                                major benefits of family meals.</p>
                                <Button variant="warning"><Link to="/src/pages/Sections.jsx" className='mylink'>learn more</Link></Button>
                            </div>
                        </div>
                    )}
                    {offer === "grilled" &&(
                        <div class="sec " >
                            <div class="sec-info">
                                <video src={video2} autoPlay loop muted/>
                                <h1>grilled</h1>
                                <img src={img2} alt=''/>
                                <p>If you are a lover of comfort and tranquility, you can enjoy your favorite meal to help you restore your activity and
                                vitality and help you to clear your mind..are a lover of comfort and tranquility, you can enjoy your favorite meal to help you restore your activity and
                                vitality and help you to clear your mind..are a lover of comfort and tranquility, you can enjoy your favorite meal to help you restore your activity and
                                vitality and help you to clear your mind..are a lover of comfort and tranquility, you can enjoy your favorite meal to help you restore your activity and
                                vitality and help you to clear your mind..</p>
                                <Button variant="warning"><Link to="/src/pages/Sections.jsx" className='mylink'>learn more</Link></Button>
                            </div>
                        </div>
                    )}
                    {offer === "burger" &&(
                        <div class="sec " >
                            <div class="sec-info">
                                <video src={video1} autoPlay loop muted/>
                                <h1>burger</h1>
                                <img src={img3} alt=''/>
                                <p>As the world is advancing day by day, it is becoming easier to get access to many kinds of food at our doorstep. Every
                                day, we all want to consume great and delicious cuisine. There are many different varieties of food accessible all
                                throughout the world. We all like different foods, however, my personal favourite is burgers. I have eaten many cuisines
                                but my favourite food is definitely a burger. I cannot resist myself when it comes to burgers..</p>
                                <Button variant="warning"><Link to="/src/pages/Sections.jsx" className='mylink'>learn more</Link></Button>
                            </div>
                        </div>
                    )}
                    {offer === "juice" &&(
                        <div class="sec " >
                            <div class="sec-info">
                                <video src={video5} autoPlay loop muted/>
                                <h1>juice</h1>
                                <img src={img4} alt=''/>
                                <p>As the world is advancing day by day, it is becoming easier to get access to many kinds of food at our doorstep.
                                    Every
                                    day, we all want to consume great and delicious cuisine. There are many different varieties of food accessible
                                    all
                                    throughout the world. We all like different foods, however, my personal favourite is burgers. I have eaten many
                                    cuisines
                                    but my favourite food is definitely a shawarma I cannot resist myself when it comes to shawarma</p>
                                <Button variant="warning"><Link to="/src/pages/Sections.jsx" className='mylink'>learn more</Link></Button>
                            </div>
                        </div>
                    )}
                    {offer === "pizza" &&(
                        <div class="sec " >
                            <div class="sec-info">
                                <video src={video3} autoPlay loop muted/>
                                <h1>pizza</h1>
                                <img src={img5} alt=''/>
                                <p>Pizza is an Italian food that was created in Italy (The Naples area). It is made with different toppings. Some of the
                                most common toppings are cheese, sausages, pepperoni, vegetables, tomatoes, spices and herbs and basil. These toppings
                                are added over a piece of bread covered with sauce. The sauce is most often tomato-based, but butter-based sauces are
                                used, too. The piece of bread is usually called a "pizza crust". Almost any kind of topping can be put over a pizza. The
                                toppings used are different in different parts of the world. Pizza comes from Italy from Neapolitan cuisine. However, it
                                has become popular in many parts of the world.</p>
                                <Button variant="warning"><Link to="/src/pages/Sections.jsx" className='mylink'>learn more</Link></Button>
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>
    </div>
)
}

export default Offers
