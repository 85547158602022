import React from 'react'
import './Landing.css'
import { Button } from 'react-bootstrap'
import coffee9 from '../images/ice cream (12).jpg'

function Landing() {
    return (
        <div class="ice-landing">
            <img src={coffee9} className='image-back'alt=''/>
            <div class="landing-para">
                <h1>foodixa</h1>
                <p>IT'S NICE TO MEET YOU</p>
                <p>Wherever we are, there is a delicious distinctive taste
                We always work hard to provide the best
                meals and the most delicious foods that suit all family members</p>
                <Button variant="outline-warning">learn more</Button>
            </div>
        </div>
    )
}

export default Landing
