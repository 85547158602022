import React from 'react'
import './PayPage.css'
import {useCart} from 'react-use-cart'
import { Button, Form } from 'react-bootstrap';
import img1 from './images/card_img.png'

function PayPage() {
    const {cartTotal}=useCart();
    return (
    <div className='main-pay'>
        <div className='pay-landing'>
            <div className='pay-landing-intro'>
                <div className='pay-intro-body'>
                    <h1>Foodixa</h1>
                    <p>IT'S NICE TO MEET YOU</p>
                    <p>Wherever we are, there is a delicious distinctive taste
                    We always work hard to provide the best
                    meals and the most delicious foods that suit all family members</p>
                    <Button variant="outline-warning">learn more</Button>
                </div>
            </div>
        </div>
        
        <h2>Total payment: {cartTotal} $</h2>
        <Form action=''>
            <div className='main-form'>
                <div className='form-side'>
                    <h3>billing information</h3>
                    <div className='input-box'>
                        <label>full name:</label>
                        <input type='text' placeholder='Your Full Name'></input>
                    </div>
                    <div className='input-box'>
                        <label>email :</label>
                        <input type='email' placeholder='example@example.com'></input>
                    </div>
                    <div className='input-box'>
                        <label>address :</label>
                        <input type='text' placeholder='street-district-city'></input>
                    </div>
                    <div className='input-box'>
                        <label>mobile :</label>
                        <input type='number' placeholder='Your mobile'></input>
                    </div>
                    <div className='input-box'>
                        <label>zip code :</label>
                        <input type='number' placeholder='123 456'></input>
                    </div>
                </div>
                <div className='form-side'>
                    <h3>payment</h3>
                    <div className='input-box'>
                        <label>cards accepted :</label>
                        <img src={img1} alt=''/>
                    </div>
                    <div className='input-box'>
                        <label>name on card :</label>
                        <input type='text' placeholder='Name On Card'></input>
                    </div>
                    <div className='input-box'>
                        <label>card number</label>
                        <input type='number' placeholder='1111-2222-3333-4444'></input>
                    </div>
                    <div className='input-box'>
                        <label>exp date :</label>
                        <input type='number' placeholder='01/2026'></input>
                    </div>
                    <div className='input-box'>
                        <label>CVV :</label>
                        <input type='number' placeholder='123'></input>
                    </div>
                </div>
            <Button variant="outline-success">submit</Button>
            </div>
        </Form>
    </div>
    )
}

export default PayPage
