import React from 'react';
import './About.css';
import planning from './images/planning.jpg'
import start from './images/start.jpg'
import services from './images/services.jpg'
import path from './images/2.jpg'


function About() {
return (
    <div className='about-main'>
        <ul>
            <li className='about-section'>
            <div class="timeline-image">
                <img src={planning} alt=''/>
                </div>
            <div class="timeline-panel">
                <div class="timeline-heading">
                    <h4>2009-2011</h4>
                    <h4 class="subheading">Our Humble Beginnings</h4>
                </div>
                <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
            </div>
            </li>
            <li className='about-section'>
            <div class="timeline-image">
                <img src={start} alt='' />
                </div>
            <div class="timeline-panel">
                <div class="timeline-heading">
                    <h4>March 2011</h4>
                    <h4 class="subheading">An Agency is Born</h4>
                    </div>
                <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
            </div>
            </li>
            <li className='about-section'>
            <div class="timeline-image">
                <img src={services} alt='' />
                </div>
                
                <div class="timeline-panel">
                    <div class="timeline-heading">
                    <h4>December 2015</h4>
                    <h4 class="subheading">Transition to Full Service</h4>
                </div>
                <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
                </div>
            </li>
            <li className='about-section'>
            <div class="timeline-image">
                <img src={path} alt=''/>
                </div>
                <div class="timeline-panel">
                <div class="timeline-heading">
                <h4>July 2020</h4>
                <h4 class="subheading">Phase Two Expansion</h4>
                </div>
                <div class="timeline-body"><p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!</p></div>
                </div>
            </li>
            <li class="about-section">
                        <div class="timeline-story">
                            <h4>
                                Be Part
                                <br />
                                Of Foodixa
                                <br />
                                Story!
                            </h4>
                        </div>
                    </li>
        </ul>

    </div>
)
}

export default About;
