import React from 'react'
import './Description.css'
import coffee1 from '../Data/images/coffee (1).jpg'
import coffee5 from '../Data/images/coffee (5).jpg'

function Description() {
  return (
    <div className='description-main'>
        <div className='description-body'>
              <div className='description-letter'>
                <h1>why coffee ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            <div className='description-image'>
              <img src={coffee1} alt=''/>
            </div>
      </div>
        <div className='description-body'>
              <div className='description-image'>
              <img src={coffee5} alt=''/>
            </div>
              <div className='description-letter'>
                <h1>why coffee from foodixa ?</h1>
                <p>Coffee, beverage brewed from the roasted and ground seeds of the tropical evergreen coffee
                  plants of African origin. Coffee is one of the three most popular beverages in the world (alongside water and tea) 
                  and one of the most profitable international commodities. Though coffee is the basis for an endless array of beverages, 
                  its popularity is mainly attributed to its invigorating effect, which is produced by caffeine, an alkaloid present in coffee.</p>
            </div>
            
      </div>
    </div>
  )
}

export default Description
